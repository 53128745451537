<template>
  <van-nav-bar title="公告" left-arrow @click="back()" />
  <van-skeleton title avatar :row="3" :loading="loading">
    <div class="details-page">
      <div class="details-content" v-for="(item, index) of noticeBarList" :key="index" @click="openMsgPopup(index)">
        <div class="content-img">
          <van-image :src="require('../assets/iocn-bell.png')" width="40px" height="40px" fit="contain"></van-image>
        </div>
        <div class="content-text">
          <div class="time">{{ formatDate(item.AddTime) }}</div>
          <div class="text">{{ item.MessageContent }}</div>
        </div>
      </div>
    </div>
  </van-skeleton>
  <van-popup v-model:show="showMsgPopup" closeable close-icon="close" position="bottom" :style="{ height: '100%' }">
    <van-nav-bar title="公告" />
    <div class="msgPopup">
      <div class="popup-time">{{ formatDate(noticePopup.AddTime) }}</div>
      <div class="popup-text">{{ noticePopup.MessageContent }}</div>
    </div>
  </van-popup>
</template>

<script>
import { getScrollingMessage } from "@/api";
export default {
  name: "noticDetails",
  components: {},
  data() {
    return {
      noticeBarList: [],
      loading: false,
      showMsgPopup: false,
      noticePopup: {},
    };
  },
  computed: {},
  watch: {},
  created() {
    this.initScrollMsg();
  },
  methods: {
    async initScrollMsg() {
      this.loading = true;
      const res = await getScrollingMessage();
      if (res) {
        this.noticeBarList = res;
        this.loading = false;
      }
    },
    formatDate(str) {
      const date = new Date(str);
      return date.toISOString().slice(0, 16).replace("T", " ");
    },
    openMsgPopup(index) {
      this.showMsgPopup = true;
      this.noticePopup = this.noticeBarList[index];
    },
    back() {
      history.back();
    },
  },
};
</script>

<style lang="scss">
.details-content {
  display: flex;
  max-height: 100px;
  padding: 10px;
  background-color: #ffffff;
  border-bottom: 1px solid #e9e9e9;
  .content-img {
    padding: 0px 10px;
  }
  .content-text {
    flex: 1;
    padding: 0px 10px;

    .time {
      font-size: 12px;
      font-weight: 400;
      color: #808080;
      margin-bottom: 8px;
    }
    .text {
      width: 100%;
      max-height: 80px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      text-overflow: ellipsis;
      word-wrap: break-word;
      line-height: 1.5;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
  }

  &:last-child {
    border: none;
  }
}

.msgPopup {
  padding: 10px;
  .popup-time {
    font-size: 12px;
    font-weight: 400;
    color: #808080;
    margin-bottom: 8px;
  }
  .popup-text {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }
}
</style>
