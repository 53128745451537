import http from './request'

Math.sum = function (...args) {
    return args.reduce((prev, curr) => {
        return prev += Number(curr)
    }, 0)
}

export const formatGame = function (obj, gameCode) {
    const balls = obj.last[gameCode].split(',')
    const total = Math.sum(...balls)
    let combo = {
        oddOrEven: {},
        bigOrSmall: {},
        bsOrOe: {}
    }
    switch (gameCode) {
        case 'game_k3':
            combo = getComboResult(total, 11)
            break;
        case 'game_ssc':
            combo = getComboResult(total, 23)
            break;
        default:
            combo = getComboResult(total, 14)
            break;
    }
    return {
        balls: balls,
        total: total,
        issue: obj.last.issue,
        nextIssue: obj.now.issue,
        openTime: obj.now.open_time,
        serverTime: obj.now.server_time,
        countDown: obj.now.time_left,
        hash: obj.last.hash,
        block: obj.last.block,
        ...combo
    }
}

export const getComboResult = function (value, max) {
    return {
        oddOrEven: { label: value % 2 ? 'odd' : 'even', color: value % 2 ? 'blue' : 'red' },
        bigOrSmall: { label: value >= max ? 'big' : 'small', color: value >= max ? 'red' : 'blue' },
        bsOrOe: { label: value >= max ? value % 2 ? 'bigOdd' : 'bigEven' : value % 2 ? 'smallOdd' : 'smallEven', color: value % 2 ? 'blue' : 'red' },
    }
}

export const getOthersGameLatest = () => {
    return http.get('/Api/GetGameLatest')
}

export const getGameLatestCnd = () => {
    return http.get('/Api/GetGameLatestCnd')
}

export const getScrollingMessage = () => {
    return http.get('/rp_api/member/get_scrolling_message')
}

export const listLotteryDrawResult = (params) => {
    return http.post('/rp_api/member/list_lottery_draw_result', { LotteryID: params })
}

export const getArticleList = (params) => {
    return http.post("/rp_api/member/article_list", params);
};

export const getArticleDetails = (Id) => {
    return http.get("/rp_api/member/article_get", {
        data: JSON.stringify({ Id }),
    });
};

export const getAdvertiseList = () => {
    return http.post("/rp_api/member/advertise_list");
};

export const getWebsiteList = () => {
    return http.post("/rp_api/member/adv_brand_site_website_list");
};

export const getGuaranteeList = () => {
    return http.post("/rp_api/member/adv_brand_site_guarantee_list");
};

export const getLatestRound = (Id) => {
    return http.get("/lt_api/get_latest_round", {
        data: JSON.stringify({ "LotteryID": Id }),
    });
}

export const getLatestTime = () => {
    return http.get("/lt_api/get_latest_time");
}


export const getGameLatest = async (gameCode) => {
    try {
        const res = await http.get(gameCode == 'dd28' ? '/Api/GetGameLatestCnd' : '/Api/GetGameLatest')
        const balls = res.last[gameCode].split(',')
        const total = Math.sum(...balls)
        let combo = {
            oddOrEven: {},
            bigOrSmall: {},
            bsOrOe: {}
        }
        switch (gameCode) {
            case 'game_k3':
                combo = getComboResult(total, 11)
                break;
            case 'game_ssc':
                combo = getComboResult(total, 23)
                break;
            default:
                combo = getComboResult(total, 14)
                break;
        }
        return {
            balls: balls,
            total: total,
            issue: res.last.issue,
            nextIssue: res.now.issue,
            openTime: res.now.open_time,
            serverTime: res.now.server_time,
            countDown: res.now.time_left,
            ...combo
        }

    } catch (error) {
        return {}
    }
}

export const getHistoryCnd = async () => {
    try {
        const res = await http.get('/Api/GetHistoryCnd')
        return res.map(item => {
            const balls = item['dd28'].split(',')
            const total = Math.sum(...balls)
            return {
                balls: balls,
                total: total,
                issue: item.issue,
                otm: item.otm,
                ...getComboResult(total, 14)
            }
        })
    } catch (error) {
        return []
    }
}

export const getOthersHistory = async () => {
    try {
        const res = await http.get('/Api/GetHistory')
        return res.reduce((prev, curr) => {
            prev.game_pc28.push(getHistoryItem(curr, 'game_pc28'))
            prev.game_ssc.push(getHistoryItem(curr, 'game_ssc'))
            prev.game_k3.push(getHistoryItem(curr, 'game_k3'))
            return prev
        }, {
            game_pc28: [],
            game_ssc: [],
            game_k3: []
        })
    } catch (error) {
        return []
    }
}

const getHistoryItem = (item, gameCode) => {
    const balls = item[gameCode].split(',')
    const total = Math.sum(...balls)
    return {
        balls: balls,
        total: total,
        issue: item.issue,
        otm: item.otm,
        block: item.block,
        ...getComboResult(total, { dd28: 14, game_pc28: 14, game_ssc: 23, game_k3: 11 }[gameCode])
    }
}

export const getGameStatus = async (gameCode, issue) => {
    return await http.get(gameCode == 'dd28' ? '/Api/GetOneGameCnd' : '/Api/GetOneGame', { game: issue })
}