<template>
  <van-nav-bar title="寻宝" />
  <div class="treasureHunt-page">
    <div class="treasureHunt-title">
      <div class="treasureHunt-tabs">
        <div :class="['item', { active: activeTab == item.code }]" v-for="item of tabList" :key="item.code" @click="changeTab(item.code)">{{ item.name }}</div>
      </div>
      <div class="treasureHunt-search">
        <van-search v-model="value" show-action shape="round" background="#4880e0" placeholder="请输入您要查找的内容" autocomplete="off" @update:model-value="updateValue">
          <template #action>
            <div @click="filterList">搜索</div>
          </template>
        </van-search>
      </div>
    </div>
    <van-skeleton title :row="5" :loading="loading">
      <div class="treasureHunt-content" v-show="activeTab == 'website'">
        <van-tabs v-model:active="activeGame" @change="changeGameTab">
          <van-tab :title="gameItem.name" v-for="gameItem of opcGames" :key="gameItem.id">
            <div class="website-item">
              <div class="item" v-for="(item, index) of filteredItems" :key="index" @click="openNewWindow(item.LinkUrl)">
                <van-image :src="item.ImageUrl" width="90%" height="46px"></van-image>
                <div class="title">{{ item.Title }}</div>
              </div>
            </div>
            <div class="noMore" v-show="!gameItem.list.length">敬请期待...</div>
          </van-tab>
        </van-tabs>
      </div>
      <div class="treasureHunt-content" v-show="activeTab == 'guarantee'">
        <div class="guarantee-item">
          <div class="item" v-for="(item, index) of filteredItems" :key="index">
            <van-image :src="item.ImageUrl" width="100%" fit="cover"></van-image>
            <div class="title">
              <div class="text">{{ item.Title }}</div>
              <div class="link-text" @click="openNewWindow(item.LinkUrl)">点击访问 ></div>
            </div>
          </div>
        </div>
      </div>
    </van-skeleton>
  </div>
</template>

<script>
import { getWebsiteList, getGuaranteeList } from "@/api";
export default {
  name: "treasureHunt",
  components: {},
  data() {
    return {
      activeTab: "website",
      activeGame: 0,
      loading: false,
      value: "",
      filteredItems: [],
      guaranteeList: [],
      tabList: {
        website: {
          code: "website",
          name: "网站大全",
        },
        guarantee: {
          code: "guarantee",
          name: "担保平台",
        },
      },
      opcGames: [
        {
          id: 1,
          code: "game_xg",
          name: "港彩",
          list: [],
        },
        {
          id: 2,
          code: "game_am",
          name: "澳彩",
          list: [],
        },
        {
          id: 3,
          code: "game_tz",
          name: "天子彩",
          list: [],
        },
        {
          id: 4,
          code: "game_pc28",
          name: "PC28",
          list: [],
        },
      ],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getGuaranteeList();
    this.getWebsiteList();
    this.filteredItems = this.opcGames[0].list;
  },
  methods: {
    async getWebsiteList() {
      this.loading = true;
      const res = await getWebsiteList();
      res.forEach((item) => {
        this.opcGames.forEach((gameItem) => {
          if (item.CategoryId === gameItem.id) {
            gameItem.list.push(item);
          }
        });
      });
      this.loading = false;
    },
    async getGuaranteeList() {
      this.loading = true;
      const res = await getGuaranteeList();
      if (res) {
        this.guaranteeList = res;
        this.loading = false;
      }
    },
    onClickButton() {},
    changeGameTab(index) {
      this.filteredItems = this.opcGames[index].list;
    },
    changeTab(code) {
      this.activeTab = code;
      if (code == "guarantee") {
        this.filteredItems = this.guaranteeList;
      } else {
        this.filteredItems = this.opcGames[this.activeGame].list;
      }
    },
    filterList() {
      let items = [];
      if (this.activeTab == "website") {
        items = this.opcGames[this.activeGame].list;
      } else {
        items = this.guaranteeList;
      }
      const query = this.value.trim().toLowerCase();
      if (query) {
        this.filteredItems = items.filter((item) => item.Title.toLowerCase().includes(query));
      }
    },
    updateValue() {
      if (this.activeTab == "website") {
        this.filteredItems = this.opcGames[this.activeGame].list;
      } else {
        this.filteredItems = this.guaranteeList;
      }
    },
    openNewWindow(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss">
.treasureHunt-page {
  .treasureHunt-title {
    background-color: #4880e0;
    padding-top: 18px;
    .treasureHunt-tabs {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      .item {
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        margin: 0px 18px;
        position: relative;
        &.active {
          font-size: 15px;
          font-weight: 600;
          color: #ffe60a;
          &::after {
            content: "";
            position: absolute;
            bottom: -6px;
            left: 50%;
            transform: translateX(-50%);
            width: 40%;
            height: 2px;
            background-color: #ffe60a;
          }
        }
      }
    }
  }
  .treasureHunt-content {
    margin-bottom: 50px;
    .website-item {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
      padding: 10px;
      .item {
        flex: 0 1 calc(25% - 10px);
        height: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        position: relative;
        .van-image {
          margin-bottom: 8px;
          img {
            border-radius: 5px;
          }
        }
        .title {
          position: absolute;
          bottom: 0;
          font-size: 12px;
          font-weight: 400;
          color: #000000;
        }
        &:last-child {
          margin-right: auto;
        }
      }
    }
    .noMore {
      display: flex;
      justify-content: center;
    }

    .guarantee-item {
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      .item {
        width: 100%;
        box-sizing: border-box;
        background-color: #ffffff;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        margin-bottom: 10px;
        img {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0px 10px;
          height: 34px;
          .text {
            bottom: 0;
            font-size: 14px;
            font-weight: 600;
            color: #000000;
          }
          .link-text {
            font-size: 12px;
            font-weight: 400;
            color: #f14400;
            padding: 2px 8px;
            border: 1px solid #f14400;
            border-radius: 15px;
          }
        }
      }
    }
  }
}
</style>
