<template>
  <router-view />
  <van-tabbar v-if="$route.meta.showTabbar" v-model="activeTab" :safe-area-inset-bottom="true">
    <van-tabbar-item to="/" icon="wap-home" color="red">V8彩</van-tabbar-item>
    <van-tabbar-item to="/news" icon="send-gift">新闻</van-tabbar-item>
    <van-tabbar-item to="/treasureHunt" icon="gift">寻宝</van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { setCookie } from "./utils";
export default {
  name: "App",
  components: {},
  data() {
    return {
      activeTab: 0,
    };
  },
  watch: {
    $route(to) {
      const tabMap = {
        "/home": 0,
        "/news": 1,
        "/treasureHunt": 2,
      };
      this.activeTab = tabMap[to.path] || 0;
    },
  },
  computed: {},
  created() {
    setCookie("lang", "zhCN");
    this.activeTab = this.getTabIndexByRoute(this.$route.path);
  },
  methods: {
    getTabIndexByRoute(path) {
      const tabMap = {
        "/home": 0,
        "/news": 1,
        "/treasureHunt": 2,
      };
      return tabMap[path] || 0;
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "DinPro";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/D-DIN.woff") format("woff");
}

@keyframes active {
  from {
    transform: scale(0.3);
  }

  to {
    transform: scale(1);
  }
}

html,
body {
  touch-callout: none;
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-user-select: none;
}

html {
  padding: 0;
  margin: 0;
}

body {
  padding: 0;
  margin: 0;
  background: #f6f6f6;
}

.link {
  color: #1110d0;
  text-decoration: underline;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #28303f;
  overflow: hidden;
  margin: 0 auto;
  max-width: 414px;
}

.van-tabbar {
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.3);
}

:root:root {
  --van-tabbar-item-text-color: #cbcbcb;
  --van-tabbar-item-active-color: #4e69be;
  --van-nav-bar-icon-color: #000;
  --van-skeleton-paragraph-background: #e0e0e0;
  --van-search-action-text-color: #ffffff;
  --van-active-color: #4880e0;
  --van-tab-active-text-color: #f14400;
  --van-tabs-bottom-bar-color: #f14400;
  --van-notice-bar-text-color: #5a8ce3;
}
</style>
